<template>
  <div style="margin: -15px -15px; background-color: #f0f2f5">
    <!-- 查询框 -->
    <div class="datePicker">
      年份：
      <div>
        <a-date-picker
          v-model="year"
          mode="year"
          v-decorator="['year']"
          placeholder="请输入"
          format="YYYY"
          class="input"
          @panelChange="panelChangeOne"
          style="width: 70%"
        />
      </div>
      <div class="date">
        关键字：
        <a-input
          style="width: 180px; margin-right: 20px"
          placeholder="请输入标题/医院/科室"
          v-model="searchName"
          @keyup.enter="searchBtn"
        ></a-input>
      </div>
      <div style="display: flex; align-items: center; margin-right: 50px">
        <span>状态</span>: &nbsp;
        <a-radio-group v-model="state" @change="searchOk">
          <a-radio-button value="0"> 未发布 </a-radio-button>
          <a-radio-button value="1"> 已发布 </a-radio-button>
        </a-radio-group>
      </div>
      <div>
        <span>到期状态</span>: &nbsp;
        <a-select
          default-value="全部"
          style="width: 120px"
          v-model="deadlinState"
          @change="handleChange"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option value="0">未到期 </a-select-option>
          <a-select-option value="1">已到期 </a-select-option>
        </a-select>
      </div>
      <a-button type="primary" style="margin-left: 30px" @click="searchBtn"
        >查询</a-button
      >
      <a-button style="margin-left: 15px; margin-right: 40px" @click="returnBtn"
        >重置</a-button
      >
    </div>
    <!-- 展示表 -->
    <div style="background-color: #fff; padding: 20px; padding-bottom: 80px">
      <a-button
        type="primary"
        style="margin-bottom: 20px; margin-right: 3%"
        @click="newlyBuilt"
      >
        新增
      </a-button>
      <a-table
        :scroll="{ x: 1600 }"
        :columns="columns"
        style="min-height: 500px; background-color: #fff"
        :data-source="HeplTable"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <span slot="operate" slot-scope="text, item, index">
          <a style="margin-right: 10px" @click="recommend(item.id,item.otherId)">推广</a>
          <a @click="editBtn(item)" style="margin-right: 10px">修改</a>
          <a-popconfirm
            title="确定删除?"
            ok-text="是"
            cancel-text="否"
            @confirm="delBtn(item.id)"
          >
            <a style="margin-right: 10px">删除</a>
          </a-popconfirm>
        </span>
        <span slot="mark" slot-scope="text, record, item, id">
          <!-- 置顶 -->
          <a-switch
            @change="onChange(record, record.isTop === 1 ? 0 : 1)"
            :checked="record.isTop !== 0"
            checked-children="置顶"
            un-checked-children="置顶"
            default-checked
          />
          <!-- 新 -->
          <a-switch
            @change="onisNewChange(record, record.isNew === 1 ? 0 : 1)"
            :checked="record.isNew !== 0"
            checked-children="新"
            un-checked-children="新"
            default-checked
            style="margin: 0 10px"
          />
          <!-- 急 -->
          <a-switch
            @change="
              onisImpatientChange(record, record.isImpatient === 1 ? 0 : 1)
            "
            :checked="record.isImpatient !== 0"
            checked-children="急"
            un-checked-children="急"
            default-checked
          />
        </span>
        <span slot="issPublish" slot-scope="text, record, item, id">
          <!-- 是否发布 -->
          <a-switch
            @change="onisPublishChange(record, record.isPublish === 1 ? 0 : 1)"
            :checked="record.isPublish !== 0"
            checked-children="是"
            un-checked-children="否"
            default-checked
          />
        </span>
        <span slot="isDeadLine1" slot-scope="text, record, item">
          <a-tag color="red" v-if="record.isDeadlineTime == 1">已到期</a-tag>
          <a-tag color="green" v-else>未到期</a-tag>
        </span>
        <!-- <span slot="addTime" slot-scope="text, record,item,id">
             <span v-if="record.updateTime !== null">{{record.updateTime}}</span>
             <span v-if="record.updateTime == null">{{record.createdTime}}</span>
           </span> -->
      </a-table>
      <div
        style="
          margin-top: 28px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <span
          style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
        >
          共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
          {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
        </span>
        <a-pagination
          style="float: right"
          show-quick-jumper
          show-size-changer
          :page-size.sync="pagination.pageSize"
          :total="pagination.total"
          v-model="pagination.current"
          @change="pageChange"
          @showSizeChange="pageChange"
        />
      </div>
    </div>
    <!-- 新建的表单 -->
    <a-drawer
      title="新增/修改"
      placement="right"
      :closable="false"
      :visible="addShow"
      @close="addCancel"
      width="44%"
    >
      <a-form-model
        :model="addForm"
        :label-col="labelCol"
        :rules="rules"
        :wrapper-col="wrapperCol"
        ref="add"
      >
        <a-form-model-item label="培训标题" prop="title">
          <a-input v-model="addForm.title" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="时长" prop="cycle">
          <!-- <a-input v-model="addForm.cycle" placeholder="请输入" /> -->
          <a-select v-model="addForm.cycle" placeholder="请选择" show-search>
            <a-select-option v-for="item in cycleList" :value="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="费用" prop="trainingExpenses">
          <!-- <a-input v-model="addForm.trainingExpenses" placeholder="请输入" /> -->
          <a-select
            v-model="addForm.trainingExpenses"
            placeholder="请选择"
            show-search
          >
            <a-select-option
              v-for="item in trainingExpensesList"
              :value="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- <a-select
                  v-model="addForm.experience"
                  placeholder="请选择"
                  show-search
              >
                <a-select-option
                  v-for="item in experienceList"
                  :value="item.name"
                  >
                  {{item.name}}
                </a-select-option>
              </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="住宿" prop="stay">
          <!-- <a-input v-model="addForm.education" placeholder="请输入" /> -->
          <a-select v-model="addForm.stay" placeholder="请选择" show-search>
            <a-select-option
              v-for="item in stayList"
              :value="item.name"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="标签" prop="tags">
          <div>
            <a-input
              v-if="tagsShow"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="addForm.inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm('add')"
              @keyup.enter.native="$event.target.blur()"
            />
            <a-tag
              v-else
              style="background: #fff; borderstyle: dashed"
              @click="showInput"
              >+标签</a-tag
            >
          </div>
          <div>
            <a-tag v-for="(list, index) in addForm.tags"
              >{{ list }}<a-icon type="close" @click="delTags(index, 'add')"
            /></a-tag>
          </div>
        </a-form-model-item>
        <a-form-model-item label="省份/城市" prop="provinceCity">
          <a-cascader
            v-model="addForm.provinceCity"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="医院" prop="hospitalId">
          <a-select
            v-model="addForm.hospitalId"
            placeholder="请选择"
            show-search
            optionFilterProp="label"
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
            @change="selectChange(addForm.hospitalId)"
          >
            <a-select-option
              v-for="item in hospitalNameList"
              :value="item.id"
              :key="item.id"
              :label="item.name"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <!-- <a-select
                  v-model="addForm.hospitalId"
                  placeholder="请选择"
                  show-search
                  @popupScroll="popupScroll_questionCode"
                  @search="select_hospital"
                  @change="selectChange(addForm.hospitalId)"
              >
                <a-select-option
                  v-for="item in hospitalNameList"
                  :value="item.id"
                  :key="item.id">
                  {{item.name}}
                </a-select-option>
              </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="一级科室" prop="firstLevelHospitalDepartment">
            <a-select
                v-model="addForm.firstLevelHospitalDepartment"
                placeholder="请选择"
                show-search
                mode="multiple"
            >
              <a-select-option
                v-for="item in ONEdepartmentNameList"
                :value="item.name"
                >
                {{item.name}}
              </a-select-option>
            </a-select>
         </a-form-model-item>
        <a-form-model-item label="二级科室" prop="hospitalDepartmentId">
          <!-- <a-select
                  v-model="addForm.hospitalDepartmentId"
                  placeholder="请选择"
                  show-search
              >
                <a-select-option
                  v-for="item in departmentNameList"
                  :value="item.id"
                  >
                  {{item.name}}
                </a-select-option>
              </a-select> -->

          <a-select
            mode="multiple"
            show-search
            option-filter-prop="children"
            v-model="addForm.hospitalDepartmentId"
            placeholder="请选择"
          >
            <a-select-option
              v-for="item in departmentNameList"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="摘要" prop="abstract2">
          <!-- <a-input v-model="addForm.abstract2" placeholder="请输入" /> -->
          <!-- 需要改滚动条 ：auto-size -->
          <a-textarea
            v-model="addForm.abstract2"
            placeholder="请输入"
            style="min-height: 100px; font-size: 16px"
          />
        </a-form-model-item>
        <!-- 需换 -->
        <a-form-model-item label="报名开始日期" prop="startTime">
          <a-date-picker
            v-model="addForm.startTime"
            style="width: 100%"
            format="YYYY-MM-DD"
          />
          <!-- <a-date-picker placeholder="请选择" style="width: 300px" v-model="addForm.birthday" /> -->
        </a-form-model-item>
        <!-- 需换 endTime-->
        <a-form-model-item label="报名结束日期" prop="endTime">
          <a-date-picker
            v-model="addForm.endTime"
            style="width: 100%"
            format="YYYY-MM-DD"
          />
        </a-form-model-item>
        <a-form-model-item label="下架时间">
          <a-date-picker
            showTime
            v-model="addForm.deadlineTime"
            style="width: 460px"
            placeholder="请选择"
            @change="deadlineTimeChange"
          />
        </a-form-model-item>
        <a-form-model-item
          prop="introduce"
          label="培训内容"
          style="margin-bottom: 30px"
        >
          <div
            v-if="addShow == true"
            style="border: 1px solid #ccc; min-height: 301px; width: 450px"
          >
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; overflow-y: hidden"
              v-model="addForm.introduce"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </a-form-model-item>
      </a-form-model>
      <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          :style="{ marginRight: '8px' }"
          @click="
            () => {
              this.addShow = false;
              //  this.btnContentType = 'save';
              //  this.btnType = 'save';
              this.$refs.add.resetFields();
              //  this.addForm = {}
            }
          "
        >
          取消
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          v-if="buttonType == 'newBuild'"
          type="primary"
          @click="addOk()"
        >
          确定
        </a-button>
        <a-button
          :style="{ marginRight: '8px' }"
          v-if="buttonType == 'edit'"
          type="primary"
          @click="editOk()"
        >
          确定
        </a-button>
      </div>
    </a-drawer>

    <!-- 推广抽屉 -->
    <a-drawer
      title="推广"
      placement="right"
      :closable="false"
      :visible="promotionShow"
      @close="promotionCancel"
      width="30%"
    >
      <!-- 社区状态按钮 -->
      <a-table
        :pagination="false"
        :row-key="(record) => record.id"
        :columns="communityColumns"
        :data-source="communityData"
        bordered
      >
        <span slot="is_open" slot-scope="text, record">
          <a-switch
            :checked="recommendState !== 0"
            v-model="record.is_open"
            checked-children="是"
            un-checked-children="否"
            @click="communityListSwitch(record.is_open)"
            default-checked
          />
        </span>
      </a-table>
      <div style="margin-top:20px">
        <a-table :row-key="record => record.id" :columns="uniAppColumns" :data-source="uniAppData" bordered>
          <span slot="is_open" slot-scope="text, record">
             <a-switch v-model="record.is_open" checked-children="是" un-checked-children="否" @click="minaRecommend(record)" default-checked />
           </span>
        </a-table>
      </div>
    </a-drawer>
  </div>
</template>

<script>
// 富文本框
import E from "wangeditor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";

import { current_date } from "@/utils/currentDate";
import { getHospital } from "@/service/hospital_api";
import { getLoginDepartment } from "@/service/dataCleaned_api";
import moment from "moment";
import City from "@/assets/city.json";
import {postminaRecommend,getminaRecommend,delminaRecommend} from "@/service/Recruiting";
import { getHospitalDepartments } from "@/service/hospital_api";
import {
  getTrain,
  postTrain,
  putTrain,
  getSelect,
  postRecommend,
  getRecommend,
  delRecommend,
  delInfo,
} from "@/service/Recruiting";

// 富文本框
const editorConfig = {
  // JS 语法
  MENU_CONF: {},
};
const signature = "";
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF["uploadImage"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData();
    formData.append("file", file);
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios
      .post(
        "https://yun-new-admin-api.yunicu.com/api/admin/common/update",
        formData
      )
      .then((res) => {
        if (res.data.code === 0) {
          insertFn(res.data.data.url); // 最后插入图片
        }
      });
  },
};
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF["uploadVideo"] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios
      .get("https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign")
      .then((res) => {
        if (res.data.code === 0) {
          const getSignature = () => {
            return res.data.data.signature;
          };
          const tcVod = new TcVod({
            getSignature: getSignature, //上传签名的函数
          });
          const uploader = tcVod.upload({
            mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
          });
          uploader.done().then((res) => {
            insertFn(res.video.url); // 进行视频处理：转码和截图
          });
        }
      });
  },
};

export default {
  components: { Editor, Toolbar },
  data() {
    return {
      promotionId:"",
      ONEdepartmentNameList:[],
      uniAppColumns:[
        {
          title: '小程序',
          key: 'title',
          dataIndex: 'title',
        },
        {
          title: '状态',
          dataIndex: 'is_open',
          key: 'is_open',
          scopedSlots: { customRender: 'is_open' },
        },
      ],
      uniAppData:[
        {
          id:1,
          title:'推荐页（首页）',
          is_open:false
        }
      ],
      deadlinState: "",
      recommendState: 0,
      communityColumns: [
        {
          title: "模块",
          key: "title",
          dataIndex: "title",
        },
        {
          title: "状态",
          dataIndex: "is_open",
          key: "is_open",
          scopedSlots: { customRender: "is_open" },
        },
      ],
      communityData: [
        {
          id: 1,
          title: "进修招聘首页",
          is_open: false,
        },
      ],
      promotionShow: false,
      mode: "default", //富文本框
      year: moment(current_date()).format("YYYY"),
      tagsShow: false,
      hospitaloPtions: [],
      //    settingShow:false,
      //    mode: 'default', // or 'simple'
      editorConfig,
      editor: null,
      //    settingEditor: null,
      //    settingHtml:'',
      toolbarConfig: {},
      //   //  html: '',
      //   //  ht4ml: '',
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      state: "1",
      addShow: false,
      rules: {
        title: [{ required: true, message: "请输入", trigger: "blur" }],
        cycle: [{ required: true, message: "请输入", trigger: "blur" }],
        trainingExpenses: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        stay: [{ required: true, message: "请输入", trigger: "blur" }],
        provinceCity: [{ required: true, message: "请选择", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择", trigger: "blur" }],
        hospitalDepartmentId: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        firstLevelHospitalDepartment: [{ required: true, message: "请选择", trigger: "blur" }],
        abstract2: [{ required: true, message: "请输入", trigger: "blur" }],
        introduce: [{ required: true, message: "请输入", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择", trigger: "change" }],
        endTime: [{ required: true, message: "请选择", trigger: "change" }],
        // salary: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      addForm: {
        type: "train",
        isPublish: 1,
        // drugOverview:'',
        // drugName :'',
        // detail:[],
        // content:[],
        tags: [],
        hospitalDepartmentId: [
          //    0 : "3",
        ],
        inputValue: "",
      },
      hospitalNameList: [],
      departmentNameList: [],
      hospitalOfficesList: [],
      stayList: [],
      cycleList: [],
      trainingExpensesList: [],
      City: City,
      hospitalPage: {
        name: undefined,
        current: 1,
      },
      //   //  modelData: [],
      //   //  model2Data: [],
      columns: [
        {
          title: "培训标题",
          dataIndex: "title",
          key: "title",
          align: "center",
          //    width:'20%',
        },
        {
          title: "标记",
          //    dataIndex: 'state',
          //    key: 'state',
          align: "center",
          width: "15%",
          scopedSlots: { customRender: "mark" },
        },
        {
          title: "医院",
          dataIndex: "hospitalName",
          key: "hospitalName",
          align: "center",
          //    width:'20%',
        },
        {
          title: "时长",
          dataIndex: "cycle",
          key: "cycle",
          align: "center",
          //    width:'20%',
        },
        {
          title: "报名时间",
          dataIndex: "startTime",
          key: "startTime",
          align: "center",
          //    width:'20%',
        },
        {
          title: "创建时间",
          dataIndex: "createdTime",
          key: "createdTime",
          align: "center",
          //    width:'20%',
        },
        {
          title: "创建人",
          dataIndex: "createdByName",
          key: "createdByName",
          align: "center",
          //    width:'20%',
        },
        {
          title: "到期状态",
          dataIndex: "isDeadLine",
          key: "isDeadLine",
          align: "center",
          scopedSlots: { customRender: "isDeadLine1" },
          width: "6%",
        },
        {
          title: "是否发布",
          //    dataIndex: 'title',
          //    key: 'title',
          //    align:'center',
          //    width:'20%',
          scopedSlots: { customRender: "issPublish" },
        },
        {
          title: "操作",
          width: "200",
          scopedSlots: { customRender: "operate" },
          fixed: "right",
        },
      ],
      HeplTable: [],

      searchName: "",
      //    editId:'',
      buttonType: "",
      itemId: "",
      //    id:"",
      //    btnType:"save",
      //    btnContentType:"save",
      //    contentIndex:0,
      //    rowTitle:"",
    };
  },
  watch: {
    expertForm(newVal, oldVal) {
      this.hospitalNameList = [];
      this.getPCH();
      this.getHospitalOfficesList();
      this.getJobTitleList();
      //   console.log("看看newVal",newVal)
      this.getExpertData(newVal.editId);
      //   this.formData={
      //     tags:[],
      //     hospital:undefined,
      //   }
    },
  }, //废
  created() {
    this.getTable();
  },
  methods: {
    async minaRecommend(record){
      if(record.is_open === true){
        const data = {
          contentId:this.promotionId,
          contentType:'EDUCATION',
          // subContentType:this.subContentType,
        }
        const response = await postminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }else{
        const data = {
          contentId:this.promotionId,
          contentType:'EDUCATION',
          // subContentType:this.subContentType,
        }
        const response = await delminaRecommend(data)
        if(response.code === 0){
          this.$message.success("操作成功！")
        }else {
          this.$message.warning(response.message)
        }
      }
    },
    handleChange() {
      this.getTable();
    },
    deadlineTimeChange(date, dateString) {
      this.$set(this.addForm, "deadlineTime", dateString);
    },
    async communityListSwitch(isOpen) {
      if (isOpen) {
        const data = {
          contentId: this.contentId,
        };
        const response = await postRecommend(data);
        if (response.code === 0) {
          this.$message.success("推广成功");
        } else {
          this.$message.warning(response.message);
        }
      } else {
        const response = await delRecommend(this.contentId);
        if (response.code === 0) {
          this.$message.success("关闭成功");
        } else {
          this.$message.warning(response.message);
        }
      }
    },
    //  推广弹窗取消
    promotionCancel() {
      this.promotionShow = false;
    },
    // table列表
    async getTable() {
      //    const response = await getTrain("recruit",this.searchName,this.pagination.pageNum,this.pagination.pageSize)
      const response = await getTrain(
        "train",
        this.searchName,
        this.pagination.pageNum,
        this.pagination.pageSize,
        this.year,
        this.state,
        this.deadlinState
      );
      if (response.code === 0) {
        this.HeplTable = response.data;
        this.pagination.total = response.count;
        console.log("表", response);
      } else {
        this.$message.warning(response.message);
      }
    },
    // 富文本框创建
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 富文本框销毁（好像不需要运行）
    beforeDestroy() {
      const editor = this.editor;
      if (editor == null) return;
      editor.destroy(); // 组件销毁时，及时销毁编辑器
    },
    // 置顶按钮开关
    async onChange(record, isTop) {
      var data = {};
      data = record;
      // data.id = record.id
      data.isTop = isTop;

      console.log("看看data的情况", data);

      const Info = await putTrain(data);
      if (Info.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败" + Info.message);
      }
    },
    // 新 开关
    async onisNewChange(record, isNew) {
      var data = {};
      data = record;
      data.isNew = isNew;
      const Info = await putTrain(data);
      if (Info.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败" + Info.message);
      }
    },
    // 急 开关
    async onisImpatientChange(record, isImpatient) {
      var data = {};
      data = record;
      data.isImpatient = isImpatient;
      const Info = await putTrain(data);
      if (Info.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败" + Info.message);
      }
    },
    // 是否发布开关
    async onisPublishChange(record, isPublish) {
      var data = {};
      data = record;
      data.isPublish = isPublish;
      const Info = await putTrain(data);
      if (Info.code === 0) {
        this.getTable();
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败" + Info.message);
      }
    },
    // 搜索框查询
    searchBtn() {
      //重置页码
      this.pagination.pageNum = 1;
      this.pagination.pageSize = 10;
      this.pagination.current = 1;
      this.getTable();
    },
    // 状态框查询
    searchOk(e) {
      switch (e.target.value) {
        // 已发布
        case "1":
          this.state = "1";
          this.pagination.pageSize = 10;
          this.pagination.pageNum = 1;
          this.pagination.current = 1;
          this.getTable();
          break;
        // 未发布
        case "0":
          this.state = "0";
          this.pageSize = 10;
          this.pageNum = 1;
          this.current = 1;
          this.getTable();
          break;
      }
    },
    // 年份
    panelChangeOne(value) {
      this.year = value;
      this.pagination.pageNum = 1;
      if (this.year) {
        this.year = moment(this.year).format("YYYY");
      }
      //   //  刷新
      this.getTable();
    },
    // 重置
    returnBtn() {
      this.searchName = ""; //输入框
      this.pagination.current = 1; //默认显示
      this.pagination.pageSize = 10; //行数
      this.pagination.pageNum = 1; //页码
      this.deadlinState = "";
      this.year = moment(current_date()).format("YYYY"); //日期框
      this.state = "1"; //已发布框
      this.getTable();
    },
    // 新建表单
    newlyBuilt() {
      this.addForm = {};
      // addForm:{
      //   type: "train",
      //   isPublish:1,
      //   tags: [],
      //   hospitalDepartmentId:[
      //   //    0 : "3",
      //   ],
      //   inputValue: '',
      //  },
      this.$set(this.addForm, "type", "train");
      this.$set(this.addForm, "isPublish", 1);
      this.$set(this.addForm, "tags", []);
      this.$set(this.addForm, "hospitalDepartmentId", []);
      this.$set(this.addForm, "inputValue", "");

      this.addShow = true;
      this.getPCH();
      this.getHospitalOfficesList();
      //  this.GetSelectFrame()
      //  this.GetSelectSalary()
      this.GetSelectEducation();
      this.GetSelectCycle();
      this.GetSelectExpenses();
      this.GetSelectONEdepartmentNameList();
      //    this.addForm.drugOverview = '';
      //    this.addForm.drugName = '';
      this.buttonType = "newBuild";
      this.expertForm = {
        formType: "add",
        formShow: true,
        type: null,
      };
    },
    // 修改表单
    async editBtn(item) {
      this.getTable();
      this.buttonType = "edit";
      this.getPCH();
      this.getHospitalOfficesList();
      this.addShow = true;
      console.log("item", item);
      this.itemId = item.id;
      this.addForm = item; //赋值
      this.addForm.firstLevelHospitalDepartment = this.addForm.firstLevelHospitalDepartment.split('、')
      this.GetSelectEducation();
      this.GetSelectCycle();
      this.GetSelectExpenses();
      this.GetSelectONEdepartmentNameList();
      //    this.addForm.provinceCity.push(item.province)
      //    this.addForm.provinceCity.push(item.city)
      //获取医院数据，填医院框
      this.hospitalNameList = [];
      // this.$set(this.addForm, 'hospitalDepartmentId', undefined)
      this.hospitalPage.current = 1;
      let data = {
        province: item.province,
        city: item.city,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
      // 填写科室框
      this.departmentNameList = [];
      const response = await getHospitalDepartments(item.hospitalId);
      if (response.code === 0) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          this.departmentNameList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
      }
    },
    // 删除选项
    async delBtn(id) {
      const response = await delInfo(id);
      if (response.code === 0) {
        this.$message.success("操作成功");
        this.getTable();
      } else {
        this.$message.warning(response.message);
      }
    },
    async recommend(id,otherId) {
      this.contentId = id;
      this.promotionId = otherId
      this.promotionShow = true;
      const response = await getRecommend(id);
      if (response.code === 0) {
        this.recommendState = response.data;
        if (response.data == 1) {
          this.communityData[0].is_open = true;
          // this.communityCode = response.data[0].code
        } else {
          this.communityData[0].is_open = false;
        }
      } else {
        this.$message.warning(response.message);
      }
      this.minaRecord()
    },
    //  小程序推广回显
     async minaRecord(){
       // 小程序推广回显  
       const data = {
         contentId:this.promotionId,
         contentType:'EDUCATION',
         // subContentType:this.subContentType,
      }
       const response = await getminaRecommend(data)
       if (response.code === 0) {
         if(response.data[0] && response.data[0].code === "YUN_ICU"){
            this.uniAppData[0].is_open = true
         }else{
           this.uniAppData[0].is_open = false
         }
       } else {
         this.$message.warning(response.message)
       }
    },
    //  弹窗取消
    addCancel() {
      this.$refs.add.clearValidate();
      //  this.addForm = {}
      this.addShow = false;
      this.hospitalNameList = []; //清空医院表单
      this.departmentNameList = []; //清空科室表单
      //    this.btnContentType = "save";
      //    this.btnType = "save";
    },
    // 提交新建表单
    async addOk() {
      this.onCreated();
      // 1.如果日历类型是object（相当于没有转化，就进行转化）
      if (typeof this.addForm.startTime == "object") {
        var hhh;
        hhh = this.addForm.startTime.format("YYYY-MM-DD");
        this.addForm.startTime = hhh;
      }
      if (typeof this.addForm.endTime == "object") {
        var hhh;
        hhh = this.addForm.endTime.format("YYYY-MM-DD");
        this.addForm.endTime = hhh;
      }
      if (typeof this.addForm.deadlineTime == "object") {
        var hhh;
        hhh = this.addForm.deadlineTime.format("YYYY-MM-DD");
        this.addForm.deadlineTime = hhh;
      }
      // 2.富文本框失焦会有默认值<p><br></p>
      if (this.addForm.introduce == "<p><br></p>") {
        delete this.addForm.introduce;
        // this.addForm.introduce = ''
        // console.log("怎么了")
      }
      //    var arr = []
      //    arr.push(this.addForm.hospitalDepartmentId)
      //    console.log("数组",arr)
      //    this.addForm.hospitalDepartmentId =  this.addForm.hospitalDepartmentId.split()

      //  console.log("表单",this.addForm)
       this.addForm.firstLevelHospitalDepartment =
        this.addForm.firstLevelHospitalDepartment.join("、");
      this.$refs.add.validate(async (valid) => {
        // 表单校验
        if (valid) {
          const response = await postTrain(this.addForm);
          if (response.code === 0) {
            // 刷新首页数据
            this.getTable();
            this.$message.success("新增成功");
            this.addShow = false;
            this.$refs.add.resetFields();
            //  this.addForm = {}
            this.hospitalNameList = []; //清空医院表单
            this.departmentNameList = []; //清空科室表单
          } else {
            this.$message.error(response.message);
          }
        } else {
          this.$message.error("请填写完整");
        }
      });
    },
    // 提交修改表单
    editOk() {
      this.addForm.id = this.itemId; //存储/id
      this.onCreated();
      console.log("修改表单", this.addForm);
      if (typeof this.addForm.startTime == "object") {
        var hhh;
        hhh = this.addForm.startTime.format("YYYY-MM-DD");
        this.addForm.startTime = hhh;
      }
      if (typeof this.addForm.endTime == "object") {
        var hhh;
        hhh = this.addForm.endTime.format("YYYY-MM-DD");
        this.addForm.endTime = hhh;
      }
      if (typeof this.addForm.deadlineTime == "object") {
        var hhh;
        hhh = this.addForm.deadlineTime.format("YYYY-MM-DD");
        this.addForm.deadlineTime = hhh;
      }
        this.addForm.firstLevelHospitalDepartment =
        this.addForm.firstLevelHospitalDepartment.join("、");
      this.$refs.add.validate(async (valid) => {
        // 表单校验
        if (valid) {
          const Info = await putTrain(this.addForm);
          if (Info.code === 0) {
            this.getTable();
            //  this.html = '';
            //  this.ht4ml = '';
            //  this.addForm.drugOverview = ''
            //  this.addForm.genericName = ''
            //  this.editor.destroy()
            //  this.editor = null
            this.$message.success("修改成功");
            this.hospitalNameList = []; //清空医院表单
            this.departmentNameList = []; //清空科室表单
            //  this.addForm = {}
            this.$refs.add.resetFields();
          } else {
            this.$message.error("操作失败" + Info.message);
          }
          this.addShow = false;
          //  this.$refs.add.resetFields()  //暂时屏蔽
        } else {
          this.$message.error("请填写完整并保存");
        }
      });
    },
    showInput() {
      this.tagsShow = true;
      //   this.$nextTick(function() {
      //     this.$refs.input.focus();
      //   });
      //    console.log("是这里把")
      //    console.log("数量",this.addForm.tags)
      if (this.addForm.tags == undefined || this.addForm.tags.length < 3) {
        this.tagsShow = true;
      } else {
        this.tagsShow = false;
        this.$message.error("标签设置上限为3个");
      }
    },
    handleInputChange(e) {
      this.addForm.inputValue = e.target.value;
    },
    handleInputConfirm(type) {
      if (this.addForm.inputValue !== "") {
        switch (type) {
          case "add":
            this.addForm.tags.push(this.addForm.inputValue);
            break;
          case "edit":
            this.editForm.tags.push(this.addForm.inputValue);
        }
        this.tagsShow = false;
        this.addForm.inputValue = "";
      } else {
        this.$message.warning("请输入内容");
      }
    },
    //删除标签
    delTags(index, type) {
      switch (type) {
        case "add":
          this.addForm.tags.splice(index, 1);
          break;
        case "edit":
          this.editForm.tags.splice(index, 1);
          break;
      }
    },
    //省份城市数据
    provinceChange(value) {
      this.hospitalNameList = [];
      //   this.$set(this.addForm, 'hospitalDepartmentId', undefined)
      this.$set(this.addForm, "hospitalDepartmentId", undefined);
      this.$set(this.addForm, "hospitalId", undefined);
      this.hospitalPage.current = 1;
      let data = {
        province: value[0],
        city: value[1],
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    //医院数据
    async getHosNameList(data) {
      const response = await getHospital(data);
      if (response.code === 0) {
        let list = response.data.rows;
        for (let i = 0; i < list.length; i++) {
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
      }
    },
    getPCH() {
      let province = [];
      this.City.forEach((item, index) => {
        let city = [];
        province.push({
          value: item.name,
          label: item.name,
          children: city,
        });
        item.cityList.forEach((list) => {
          city.push({
            value: list.name,
            label: list.name,
            loading: false,
          });
        });
        province[index].children = city;
      });
      this.hospitaloPtions = province;
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment();
      if (response.code === 0) {
        let oneOff = [];
        let list = response.data.wySy;
        list.forEach((item, index) => {
          let twoOff = [];
          oneOff.push({
            value: item.title,
            label: item.title,
            children: twoOff,
          });
          item.zoffices.forEach((lists) => {
            twoOff.push({
              value: lists.title,
              label: lists.title,
            });
          });
          oneOff[index].children = twoOff;
        });
        this.hospitalOfficesList = oneOff;
      }
    },
    async getJobTitleList(identify) {
      const response = await getTitle2(identify);
      if (response.code === 0) {
        this.jobTitleList = response.data.row;
      }
    },
    //滚动条监听
    popupScroll_questionCode(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.addForm.provinceCity;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 科室滚动条监听
    departmentPopupScroll(e) {
      const { target } = e;
      const scrollHeight = target.scrollHeight - target.scrollTop; //滑动总高度
      const clientHeight = target.clientHeight; //滑动最低高度
      if (scrollHeight < clientHeight + 2) {
        this.hospitalPage.current++;
        let values = this.addForm.province;
        const data = {
          province: values[0],
          city: values[1],
          name: this.hospitalPage.name,
          pagenum: this.hospitalPage.current,
          pagesize: 10,
        };
        this.getHosNameList(data);
      }
    },
    // 医院框输入
    select_hospital(value) {
      this.hospitalNameList = [];
      this.departmentNameList = [];
      this.hospitalPage.name = value;
      let values = this.addForm.provinceCity;
      const data = {
        province: values[0],
        city: values[1],
        name: value,
        pagenum: 1,
        pagesize: 10,
      };
      this.getHosNameList(data);
    },
    // 医院框切换
    async selectChange(hospital) {
      this.departmentNameList = []; //科室框清空
      //   this.$set(this.addForm, 'hospitalDepartmentId', undefined)
      this.$set(this.addForm, "hospitalDepartmentId", undefined);
      const response = await getHospitalDepartments(hospital);
      if (response.code === 0) {
        let list = response.data;
        for (let i = 0; i < list.length; i++) {
          this.departmentNameList.push({
            id: list[i].id,
            name: list[i].name,
          });
        }
        console.log("看列表", this.departmentNameList);
        // console.log("看列表",this.describeList)
      } else {
        this.$message.error("获取错误，请联系管理员~" + response.message);
      }
    },
    // 分页查询
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    // // 下拉框接口（经验）
    // async GetSelectFrame(){
    //   this.experienceList = []  //置空不然一直叠加选项
    //   const response = await getSelect("JY")
    //   // this.HeplTable = response.data
    //   // this.pagination.total = response.count
    //   if(response.code === 0){
    //      let list = response.data[0].value
    //      for (let i=0;i<list.length;i++){
    //        this.experienceList.push({
    //          id: i,
    //          name: list[i],
    //        })
    //      }
    //   }
    //   else{
    //     this.$message.warning(response.message)
    //   }
    // },
    // // 下拉框接口（薪资）
    // async GetSelectSalary(){
    //   this.SalaryList = []  //置空不然一直叠加选项
    //   const response = await getSelect("XZ")
    //   console.log("薪资",response)
    //   // this.HeplTable = response.data
    //   // this.pagination.total = response.count
    //   if(response.code === 0){
    //      let list = response.data[0].value
    //      for (let i=0;i<list.length;i++){
    //        this.SalaryList.push({
    //          id: i,
    //          name: list[i],
    //        })
    //      }
    //   }
    //   else{
    //     this.$message.warning(response.message)
    //   }
    // },
    // 下拉框接口（学历）
    async GetSelectEducation() {
      this.stayList = []; //置空不然一直叠加选项
      const response = await getSelect("ZS");
      // console.log("薪资",response)
      // this.HeplTable = response.data
      // this.pagination.total = response.count
      if (response.code === 0) {
        let list = response.data.value;
        for (let i = 0; i < list.length; i++) {
          this.stayList.push({
            id: i,
            name: list[i],
          });
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    // 下拉框（周期）
    async GetSelectCycle() {
      this.cycleList = []; //置空不然一直叠加选项
      const response = await getSelect("SC");
      if (response.code === 0) {
        let list = response.data.value;
        for (let i = 0; i < list.length; i++) {
          this.cycleList.push({
            id: i,
            name: list[i],
          });
        }
      } else {
        this.$message.warning(response.message);
      }
    },
    // 下拉框（费用）
    async GetSelectExpenses() {
      this.trainingExpensesList = []; //置空不然一直叠加选项
      const response = await getSelect("FY");
      if (response.code === 0) {
        let list = response.data.value;
        for (let i = 0; i < list.length; i++) {
          this.trainingExpensesList.push({
            id: i,
            name: list[i],
          });
        }
      } else {
        this.$message.warning(response.message);
      }
    },
      // 下拉框接口（一级科室）
  async GetSelectONEdepartmentNameList(){
    this.ONEdepartmentNameList = []  //置空不然一直叠加选项
    const response = await getSelect("YJKS")
    
    if(response.code === 0){
       let list = response.data.value
       for (let i=0;i<list.length;i++){
         this.ONEdepartmentNameList.push({
           name: list[i],
         })
       } 
    }
    else{
      this.$message.warning(response.message)
    }
  },
    // 根据医院id获取科室
    // async obtainHospitalDepartments(){
    //   const response = await getHospitalDepartments(this.manageId);
    //   if (response.code === 0) {
    //     this.manageList = response.data;
    //   } else {
    //     this.$message.error("获取错误，请联系管理员~" + response.message);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.datePicker {
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  .status {
    float: right;
    position: absolute;
    right: 50px;
  }
}
::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
</style>
